@import url('https://fonts.googleapis.com/css?family=Pattaya');

.note{font-size:0.8em}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

audio:focus{outline: none;}

.loadinggif{  
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width:100%
}

#root{
  background-color: #ececec
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header{
overflow: hidden;
  width:100%;
  height:122px;
  background: #016797;
background: -moz-linear-gradient(top,  #016797 0%, #00354f 100%);
background: -webkit-linear-gradient(top,  #016797 0%,#00354f 100%);
background: linear-gradient(to bottom,  #016797 0%,#00354f 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#016797', endColorstr='#00354f',GradientType=0 );
/*box-shadow: 0px 10px 50px 0px rgba(0,0,0,0.71);*/
}

.header a, .header a:active, .header a:visited, .header a:hover {text-decoration: none; color:white}

.dropshadow{
  box-shadow: 0px 10px 50px 0px rgba(0,0,0,0.71);
}

.textcenter{
  text-align: center
}

.floatright{
  float: right;
}

.block{
  box-shadow: 0px 4px 25px 0px rgba(0,0,0,0.30);
  border-radius: 5px;
  padding: 20px;
  margin:15px auto;
  background-color: white;
}

.block h4{
  font-size: 1rem;
}

.logo{
  font-family: 'Pattaya';
  font-size: 3em;
  color:#fff;
  width:330px;
  height:85px;
  margin-top: 20px
}



#weatherforecast{
  display: flex;
  width:100%;
  overflow: auto;
}

.weatherforecastitem{
  width:200px;
  font-size:0.8em;
}

.weatherimg{
  background-color: #c3c3c3

}


.weatherforecastitem ul{
  list-style-type: none;
  margin:0;
  padding:3px;
}

#googleplaces ul{
  list-style: none;
  column-count: 4;
  padding:0
}

#googleplaces li{
  margin-bottom:5px;
}

#googleplaces ul img{
  margin-right: 10px;
  width:20px;
}

#googleplaces ul a{
  margin-top: 2px
}

.defaultlist{list-style: none; padding: 0;}
.defaultlist li{ padding: 20px 0 0 0; margin-bottom: 0;}

.newsdate{font-size: 0.8em; display: block;}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    .logo{padding: 0 15px;}
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .centercontent{
        width: 750px;
        margin: 0 auto;
    }
    .flagimage{float:right; border: 1px solid #ddd;}
    .logo{padding: 0;}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .centercontent {
        width: 970px;
        margin: 0 auto;
    }
    .doughnutchart{
      min-height: 300px;
    }

    
}



$primaryColor: #d4d4d4;


.searchblock{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  
  .searchform{
   position: relative; 
    
  }

  .searchinput{
    width:70%
  }

.searchresults{            
    position: absolute;
    z-index: 1;      
    background-color: white;  
    /* box-shadow: 0px 6px 10px -1px rgba(0,0,0,0.75);    */
   
  }

  .showresults{
    display:block;      
    position: absolute;
    z-index: 1;
    /*top:57px;*/
    left:0;
    right:0;
    background-color: white;      
    
    border-left: 1px solid $primaryColor;
    border-bottom: 1px solid $primaryColor;
    border-right: 1px solid $primaryColor;
  }

  .searchresults ul{
    list-style: none;
    padding: 0;
    margin:0;
  }
  .searchresults li{
    padding: 0;
    margin:0;
  }

  .searchresults li a{
    text-decoration: none;
    display: block;
    padding:2px 5px;
    color:#2b2b2b;
  }

  .searchresults li a:hover{
    background-color:#e2e2e2
  }